import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92')
];

export const server_loads = [0,6,2];

export const dictionary = {
		"/(orion)": [~37,[6]],
		"/404": [88],
		"/(auth)/admin": [17,[2]],
		"/(backoffice)/backoffice": [27,[3]],
		"/(backoffice)/backoffice/bandeiras": [28,[3]],
		"/(backoffice)/backoffice/bandeiras/[id]": [29,[3]],
		"/(backoffice)/backoffice/disparadores": [30,[3]],
		"/(backoffice)/backoffice/marretagens": [31,[3]],
		"/(backoffice)/backoffice/vendas": [32,[3]],
		"/(orion)/banners": [38,[6,7]],
		"/(orion)/banners/cadastrar": [40,[6,7]],
		"/(orion)/banners/[id]": [39,[6,7]],
		"/(bussola)/bussola": [33,[4]],
		"/(bussola)/bussola/(steps)/analise": [34,[4,5]],
		"/(bussola)/bussola/(steps)/analise/[lojaId]": [35,[4,5]],
		"/(bussola)/bussola/(steps)/analise/[lojaId]/[categoria]": [36,[4,5]],
		"/(auth)/cadastrar": [18,[2]],
		"/(orion)/cadastro-promocao": [~41,[6,8]],
		"/(orion)/categorias": [42,[6,9]],
		"/(orion)/categorias/cadastrar": [44,[6,9]],
		"/(orion)/categorias/[id]": [43,[6,9]],
		"/(orion)/cursos": [45,[6,10]],
		"/(orion)/cursos/gerenciar/(listar)": [48,[6,10]],
		"/(orion)/cursos/gerenciar/cadastrar": [50,[6,10]],
		"/(orion)/cursos/gerenciar/[id]": [49,[6,10]],
		"/(orion)/cursos/pesquisar": [51,[6,10]],
		"/(orion)/cursos/solucoes": [~52,[6,10]],
		"/(orion)/cursos/solucoes/[id]": [~53,[6,10]],
		"/(orion)/cursos/solucoes/[id]/[treinamento]": [54,[6,10]],
		"/(orion)/cursos/[id]": [46,[6,10]],
		"/(orion)/cursos/[id]/[aula]": [47,[6,10]],
		"/erro": [~90],
		"/(orion)/fidelizamais": [55,[6,11]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]": [~56,[6,11,12]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]/ativacao": [57,[6,11,12]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]/configuracoes": [58,[6,11,12]],
		"/(orion)/fidelizamais/criar/[tipoCampanha]/revisao": [59,[6,11,12]],
		"/(orion)/fidelizamais/gerenciar": [60,[6,11]],
		"/(orion)/fidelizamais/minhas-lojas": [61,[6,11]],
		"/icons": [91],
		"/(auth)/login": [19,[2]],
		"/(auth)/logout": [~20,[2]],
		"/(orion)/mais": [62,[6]],
		"/(orion)/mais/noticias": [63,[6]],
		"/(orion)/mais/noticias/lancamentos": [65,[6]],
		"/(orion)/mais/noticias/lancamentos/[id]": [66,[6]],
		"/(orion)/mais/noticias/[id]": [64,[6]],
		"/(orion)/mais/perfil": [67,[6]],
		"/(orion)/medicamentos": [68,[6,13]],
		"/(orion)/medicamentos/pbm": [69,[6,13]],
		"/(orion)/medicamentos/pbm/gerenciar": [70,[6,13]],
		"/(orion)/medicamentos/pbm/orientacoes": [71,[6,13,14]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar": [73,[6,13,14,15]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar/cadastrar": [75,[6,13,14,15]],
		"/(orion)/medicamentos/pbm/orientacoes/gerenciar/[id]": [74,[6,13,14,15]],
		"/(orion)/medicamentos/pbm/orientacoes/[id]": [72,[6,13,14]],
		"/(orion)/noticias": [76,[6]],
		"/(orion)/noticias/gerenciar": [78,[6,16]],
		"/(orion)/noticias/gerenciar/cadastrar": [80,[6,16]],
		"/(orion)/noticias/gerenciar/[id]": [79,[6,16]],
		"/(orion)/noticias/lancamentos": [81,[6]],
		"/(orion)/noticias/lancamentos/[id]": [82,[6]],
		"/(orion)/noticias/[id]": [77,[6]],
		"/(orion)/ofertas": [83,[6]],
		"/(orion)/perfil": [84,[6]],
		"/privacidade": [92],
		"/(orion)/promocoes": [~85,[6]],
		"/(auth)/recuperar-senha": [21,[2]],
		"/(auth)/redefinir-senha": [22,[2]],
		"/(auth)/sso": [~23,[2]],
		"/(auth)/sso/[token]": [24,[2]],
		"/(orion)/trade": [86,[6]],
		"/(orion)/troca-email": [87,[6]],
		"/(auth)/v2sso": [~25,[2]],
		"/(auth)/v2sso/[token]": [~26,[2]],
		"/[...naoencontrada]": [~89]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';